import { React, Suspense, useState, useEffect, useRef, useCallback } from "react";
import PropTypes, { element } from "prop-types";
import styles from "../../../assets/styles/components/editor/Editor.module.sass";
import { Canvas, useFrame, useThree, useLoader } from "@react-three/fiber";
import { OrbitControls, TrackballControls, Sphere, OrthographicCamera, PerspectiveCamera, View } from "@react-three/drei";
import { Vector3, BufferGeometry, TextureLoader, WireframeGeometry, CameraHelper  } from "three";
import { ConvexGeometry } from "three/examples/jsm/geometries/ConvexGeometry";
// import { triangulation } from "../../core/triangulation";
import circle from "../../../assets/images/geometry/circle.png";

const Normalization = async (points) => {
    console.log(points);
    if (points.length <= 0)
        return points;
    const maxX = Math.max(...points.map(e => { return e.x; }));
    const maxY = Math.max(...points.map(e => { return e.y; }));
    const maxZ = Math.max(...points.map(e => { return e.z; }));

    const minX = Math.min(...points.map(e => { return e.x; }));
    const minY = Math.min(...points.map(e => { return e.y; }));
    const minZ = Math.min(...points.map(e => { return e.z; }));

    for (let i = 0; i < points.length; i++) {
        points[i].x=(points[i].x-minX)/(maxX-minX);
        points[i].y=(points[i].y-minY)/(maxY-minY);
        points[i].z=(points[i].z-minZ)/(maxZ-minZ);
    }

    return points;
};

async function getGeometryBasic(points){
    const geometryBuffer = new BufferGeometry()
        .setFromPoints(
            [...points]
                .map((element) => { return new Vector3(element.x, element.y, element.z); })
            );
    return geometryBuffer;
}

async function getGeometryTriangle(triangle){
    const triangleBuffer = new BufferGeometry().setFromPoints(triangle);
    return triangleBuffer;
}

async function getGeometryWireframe(geometryBuffer){
    const geometryWireframe = new WireframeGeometry(geometryBuffer);
    return geometryWireframe;
}

async function getGeometryConvex(geometryBuffer){
    const vertices = [];
    for (let i = 0; i < geometryBuffer.getAttribute("position").count; i++)
        vertices.push(new Vector3().fromBufferAttribute(geometryBuffer.getAttribute("position"), i));
    const geometryConvex = new ConvexGeometry(vertices);
    return geometryConvex;
}

const ArrayRenameKey = (array, oldKey, newKey) => {
    if(oldKey == newKey)
        return array;
    array.map((obj) => {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
        return obj;
    });
    return array;
};

const CameraController = (props) => {
    const camera = useThree((state) => state.camera);

    useEffect(() => {
        console.log("Control style changed to: ", props.controlStyle);
        if (props.controlStyle === "orbit") {
            camera.up = new Vector3(0, 0, 1);
            camera.lookAt(0, 0, 0);
        }
    }, [props.controlStyle, camera]);

    if (props.controlStyle === "orbit") {
        return <OrbitControls rotateSpeed={2} />;
    }
    return <TrackballControls rotateSpeed={5} />;
};

const Group = ({ type, points, triangle, bias = { x: -0.5, y: -0.5, z: -0.5 }, colors = { points: "#9c6666", lines: "#9c88ff", mesh: `#22ff44` }, hideLines = false }) => {
    const [geometryBuffer, setGeometryBuffer] = useState();
    const [triangleBuffer, setTriangleBuffer] = useState();
    const [geometryWireframe, setGeometryWireframe] = useState();
    const [geometryConvex, setGeometryConvex] = useState();

    //TODO: сложный код
    useEffect(() => {
        // Выполнить нормализацию (вписать все точки в промежуток от [0:1])
        Normalization(points).then( (normolize) => {
            console.log("Выполнена нормализация:", normolize);
            getGeometryBasic(
                normolize.map((element) => { return new Vector3(element.x, element.y, element.z); })
            )
            .then(result => {
                // Вывод точек (быстрая функция)
                setGeometryBuffer(result);
                getGeometryConvex(result).then( r =>  setGeometryConvex(r));
            });
            // Просчет поверхности (выполнение сглаживания) производится на node.js сервера
            const callBackendAPI = async () => {
                //http://localhost:5000/triangle

                const response = await fetch(new URL("http://212.113.117.140:5000/triangle"), {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ points: normolize })
                });
                const body = await response.json();
        
                if (response.status !== 200) {
                    throw Error(body.message);
                }
                return body;
            };
            callBackendAPI()
                .then(res => {
                    // Записывает геометрию
                    getGeometryTriangle(res.triangle)
                        .then(result => {
                            setTriangleBuffer(result);
                            getGeometryWireframe(result).then( r =>  setGeometryWireframe(r));
                        });
                })
                //TODO: Ошибки на сервере
                .catch(err => console.log(err));
        
        });
    }, [points, type]);


    const CircleImg = useLoader(TextureLoader, circle);
    const pointsMaterial = <pointsMaterial map={CircleImg} size={0.03} color={colors.points} transparent />;
    const lineMaterial = <lineBasicMaterial attach="material" color={colors.lines} linewidth={10} linecap={"round"} linejoin={"round"} />;
    const meshMaterial = <meshBasicMaterial transparent={true} opacity={0.2} color={colors.mesh} />;
    



    switch (type) {
        case "line":
            type = <line geometry={geometryBuffer}>
                {lineMaterial}
            </line>;
            break;
        case "points":
            type = <points geometry={geometryBuffer}>
                {pointsMaterial}
            </points>;
            break;
        case "mesh":
            type = <><mesh geometry={triangleBuffer}>
                {meshMaterial}
            </mesh>
            {!hideLines && <lineSegments geometry={geometryWireframe}>
                    <lineBasicMaterial color={colors.lines} />
                </lineSegments>}
            </>;
            break;
        case "mesh-full":
            type = <><mesh geometry={geometryConvex}>
                {meshMaterial}
            </mesh>
            {
                !hideLines &&
                <lineSegments geometry={geometryConvex}>
                    <lineBasicMaterial color={colors.lines} />
                </lineSegments>
            }
            </>;
            break;
        default:
            type = null;
    }

    return <group position={[bias.x, bias.y, bias.z]}>
        {type}
    </group>;
};

function Сonvolution(points, nameX, nameY, nameZ){
    let _points = [...points ];
    let convolution = [];

    for (let i = 0; i < _points.length; i++) {
        const element = _points[i];
        for (let j = 0; j < convolution.length; j++) {
            if(convolution[j][nameX] == element[nameX] && convolution[j][nameY] == element[nameY]){
                if ( convolution[j][nameZ].max < element[nameZ] )
                    convolution[j][nameZ].max = element[nameZ];
                else if ( convolution[j][nameZ].min > element[nameZ] )
                    convolution[j][nameZ].min = element[nameZ];
                continue;
            }
        }
        convolution.push({ ...element });
        convolution[convolution.length - 1][nameZ] = { max:convolution[convolution.length - 1][nameZ], min: convolution[convolution.length - 1][nameZ] };
    }

    return convolution;
}

export const CanvasArea = ({ points, type = "line", cameraControl = "orbit", cameraType = "perspective", axis = false, color = null, ...props }) => {
    // const nameX = "x";
    // const nameY = "y";
    // const nameZ = "R";
    // const max = Сonvolution(points, nameX, nameY, nameZ).map(item => { return { x:item[nameX], y:item[nameY], z:item[nameZ].max }; });
    const [groups, setGroups] = useState([]);

    const canvasRef = useRef(null);

    useEffect(()=>{
        console.log("changedPoints:", points);
        setGroups([
            <Group
                type={type}
                points={points}
                colors={
                        {
                            points: props.colorPoints ? props.colorPoints : color ? color : "#9c6666",
                            lines: props.colorLines ? props.colorLines : color ? color : "#9c88ff",
                            mesh: props.colorMesh ? props.colorMesh : color ? color : `#22ff44`
                        }
                }
                hideLines = { props.hideLines? props.hideLines : false }
            />
        ]);
    }, [points, type]);

    return (
            <Canvas ref={canvasRef} camera={{ position: [0, 1, 1], far: 10, near: 0.1, zoom: cameraType === "orthographic" ? 400 : 1 }} orthographic={ cameraType === "orthographic" } >
                <CameraController controlStyle={cameraControl} />
                {axis && <axesHelper args={[1]} /> }
                {
                    points?.length &&
                        // <Group
                        //     type={type}
                        //     points={points}
                        //     colors={
                        //             {
                        //                 points: props.colorPoints ? props.colorPoints : color ? color : "#9c6666",
                        //                 lines: props.colorLines ? props.colorLines : color ? color : "#9c88ff",
                        //                 mesh: props.colorMesh ? props.colorMesh : color ? color : `#22ff44`
                        //             }
                        //     }
                        //     hideLines = { props.hideLines? props.hideLines : false }
                        // />
                        groups
                }
            </Canvas>
    );
};

CanvasArea.propTypes = {
    type: PropTypes.oneOf(["line", "points", "mesh", "mesh-full"]),
    cameraControl: PropTypes.oneOf(["orbit", "trackball"]),
    cameraType: PropTypes.oneOf(["orthographic", "perspective"]),
    axis: PropTypes.bool,
    points: PropTypes.arrayOf(
        PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
            z: PropTypes.number
        })
    ),
    color: PropTypes.string
};
