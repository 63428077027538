import { React } from "react";
import { Heading } from "../../Heading";
import styles from "../../../assets/styles/components/pages/main/Feedback.module.sass";
import linkStyles from "../../../assets/styles/components/Link.module.sass";
import { FeedbackForm } from "./FeedbackForm";

export const Feedback = () => {
  return (
    <section className={styles["feedback"]}>
        <Heading level={2}>
          Обратная связь
        </Heading>
        <p className={styles["text"]}>
          Приглашаем к сотрудничеству.
        </p>
        <div className={styles["content"]}>
          <FeedbackForm />
          <address className={styles["contacts"]}>
            <div className={styles["contact"]}>
              <span className={styles["text"]}>Наш e-mail:</span>
              <a className={linkStyles["link"]} href="mailto:ap@triaxes.com">ap@triaxes.com</a>
            </div>
            {/* <div className={styles["contact"]}>
              <span className={styles["text"]}>Наш телефон (telegram,&nbsp;whatsapp):</span>
              <a className={linkStyles["link"]} href="tel:+79216157733">+7(921)615-77-33</a>
            </div> */}
          </address>
        </div>
      </section>
  );
};