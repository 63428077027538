import { React } from "react";
import { Header } from "../components/header/Header";
import { Outlet } from "react-router";

export const Layout = () => {
  return (
    <>
    <Header />
    <Outlet />
    </>
  );
};