import { React, Suspense, useState, useEffect, useRef, useCallback } from "react";
import PropTypes, { element } from "prop-types";
import styles from "../../../assets/styles/components/editor/Editor.module.sass";
import { CanvasArea } from "./Canvas";
import { Button } from "../../Button";
import { Icon } from "../../Icon";


export const ViewArea = ({ points = undefined, param = undefined, type = "line", cameraControl = "orbit", cameraType = "perspective", axis = false, color = null, loading = false, ...props }) => {
    // const nameX = "x";
    // const nameY = "y";
    // const nameZ = "R";
    // const max = Сonvolution(points, nameX, nameY, nameZ).map(item => { return { x:item[nameX], y:item[nameY], z:item[nameZ].max }; });
    let localStore = window.localStorage.getItem("points") ? JSON.parse(window.localStorage.getItem("points")) : [];

    let _points =
            points ?
                param !== undefined ?
                    points.map(e => { return { ...e, z:e[param] }; } )
                    : points
                : localStore;

    const [canvas, setCanvas] = useState(<CanvasArea points={ _points } type={type} cameraControl={cameraControl} cameraType={cameraType} axis={axis} color={color} />);

    useEffect(()=>{
        let _points =
            points ?
                param !== undefined ?
                    points.map(e => { return { ...e, z:e[param] }; })
                    : points
                : localStore;
        setCanvas(<CanvasArea points={ _points } type={type} cameraControl={cameraControl} cameraType={cameraType} axis={axis} color={color} />);
    }, [points, param, type]);

    return (
        <div className={styles["viewArea"]} style={{ height: props.height }}>
            { canvas }
            { loading &&
                <div className={styles["loadPanel"]}>
                    <Icon icon="line-md:loading-twotone-loop" rotate={0} size={24} />
                    <span>{loading}</span>
                </div>
            }
        </div>
    );
};

ViewArea.propTypes = {
    type: PropTypes.oneOf(["line", "points", "mesh", "mesh-full"]),
    cameraControl: PropTypes.oneOf(["orbit", "trackball"]),
    cameraType: PropTypes.oneOf(["orthographic", "perspective"]),
    axis: PropTypes.bool,
    points: PropTypes.arrayOf(
        PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
            z: PropTypes.number
        })
    ),
    color: PropTypes.string
};
