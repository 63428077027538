import clsx from "clsx";
import styles from "../../../assets/styles/components/editor/ViewAreaControl.module.sass";
import { Icon } from "../../Icon";
import { Input } from "../../form/Input";
import { Select } from "../../form/Select";
import { Tag } from "../../Tag";
import { useState, useEffect, useCallback, useRef } from "react";
import { Button } from "../../Button";

const PanelControlParams = ( { className, options, onChange, onChangeSelectType, ...prors } ) => {

    

    return (
        <div className={ clsx(styles["params"], { [styles[className]]: className !== undefined } ) }>
            <h3>Параметры вывода данных</h3>
            <div><span>Отобразить: </span><Select options={options} chooseOptionText="-" onChange={(value) => { onChange(value); }} /></div>
            <div><span>Вид: </span><Select options={["line", "points", "mesh", "mesh-full"]} chooseOptionText="points" onChange={(value) => { onChangeSelectType(value); }} /></div>
        </div>
    );
};

export const ViewAreaControl = ( { className, paramOptions, setParam, onChangeSelectType, ...props } ) => {

    const [active, setActive] = useState("params");

    return (
        <div className={ clsx(styles["panel"], className) }>
            <div className={ clsx(styles["labels"]) }>
                <div className={ clsx(styles["label"]) } onClick={() => setActive("params")}>
                    <Icon
                        icon="fluent:math-formula-16-filled"
                        rotate={0}
                        size={24}
                    />
                </div>
            </div>
            <div className={ clsx(styles["panels"]) }>
                <PanelControlParams className={active === "params" ? "active" : undefined } options={paramOptions} onChange={setParam} onChangeSelectType={onChangeSelectType} />
            </div>
        </div>
    );
};