import { React, useState, useRef, useEffect } from "react";
import { MainProjectSettings } from "../components/pages/editor/MainProjectSettings";
import { LevelLineSettings } from "../components/pages/editor/LevelLineSettings";
import { Solutions } from "../components/pages/editor/Solutions";
import { ViewArea } from "../components/editor/ViewArea/ViewArea";
import { ViewAreaControl } from "../components/editor/ViewArea/ViewAreaControl";
import { Panel } from "../components/editor/Panel";
import styles from "../assets/styles/components/pages/editor/Main.module.sass";
import clsx from "clsx";

const DELAYREQUEST = 1000;
const CRITIACALDELEY = 60 * 1000; // 1 min

export const Editor = () => {
  const [activeTabId, setActiveTabId] = useState(0);

  const [points, setPoints] = useState();
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState();
  const [param, setParam] = useState();

  const [type, setType] = useState("points");
  

  const getData = async (outPutRequest) => {
    console.log(outPutRequest.output.name_file);

    

    let CriticalAwait = CRITIACALDELEY;
    const getPoints = async (name_file) => {
      fetch(new URL("http://212.113.117.140/calculate/calculate.php?name_file="+name_file), { method: "GET" }).then((r) => {
          r.json().then(result=>{
            setLoading("Идет вычисление");
            if(result.output == "Идет вычисление"){
              CriticalAwait-=DELAYREQUEST;
              if(CriticalAwait>0)
                setTimeout(getPoints, DELAYREQUEST, name_file);
              else{
                console.warn("Слишком долгое ожидание ответа");
                //TODD: выбрать другой путь оповещения
                setLoading("Время ожидания первышено");
              }
            }else{
              setLoading(false);
              //TODO: Замена переменной с помощью панельки
              if(typeof result.output === "object"){
                if(Array.isArray(result?.output) && result?.output?.length > 0){
                  setParams(Object.keys(result.output[0]).filter(e => e != "x" && e != "y" ));
                }
                setPoints(result.output);
              }
            }
          });
      });
    };

    getPoints(outPutRequest.output.name_file);

    
  };

  const viewAreaRef = useRef();
  const [viewAreaHeight, setViewAreaHeight] = useState(0);

  const onResize = () => {
    setViewAreaHeight(viewAreaRef.current.offsetHeight - 1);
  };

  useEffect(() => {
    setViewAreaHeight(viewAreaRef.current.offsetHeight - 1);
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <main className={styles["main"]}>
      <div className={styles["view-area"]} ref={viewAreaRef}>
      <ViewArea
        height={viewAreaHeight}
        points={points}
        axis
        cameraControl="orbit"
        cameraType="perspective"
        type={type}
        loading = {loading}
        param = {param}
      />
      </div>
      
      <div className={clsx(styles["tab"], styles["tab-left"], styles["tab-active"])}>
        <Panel callBack={getData}/>
      </div>
      <div className={clsx(styles["tab"], styles["tab-right"], styles["tab-active"])}>
        <ViewAreaControl paramOptions={params} setParam={setParam} onChangeSelectType={setType}/>
      </div>
    </main>
  );
};