import "./assets/styles/base.sass";
import { router } from "./pages/router";
import { React } from "react";
import { RouterProvider } from "react-router-dom";

function App() {
  return (
    <>
      <RouterProvider router={router}/>
    </>
  );
}

export default App;
