// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Media parameters */
/* Colors */
/* Fonts */
.Pagination_pagination__rLZ83 {
  display: flex;
  gap: 24px;
}

.Pagination_pagination-button__U8CvB {
  font-size: 20px;
  line-height: 120%;
  cursor: pointer;
  padding: 4px 8px;
  font-family: "Roboto Mono", "Arial", monospace;
  background-color: transparent;
  transition: all 0.3s ease;
  border: none;
}
.Pagination_pagination-button__U8CvB:hover {
  color: #8000FF;
}
.Pagination_pagination-button-active__cN\\+w7 {
  color: #8000FF;
  border-bottom: 1px solid #8000FF;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/variables.sass","webpack://./src/assets/styles/components/Pagination.module.sass","webpack://./src/assets/styles/mixins.sass"],"names":[],"mappings":"AAAA,qBAAA;AASA,WAAA;AAWA,UAAA;AChBA;EACI,aAAA;EACA,SAAA;AAAJ;;AAEA;ECLI,eAAA;EACA,iBAAA;EDMA,eAAA;EACA,gBAAA;EACA,8CDSS;ECRT,6BAAA;EACA,yBAAA;EACA,YAAA;AAEJ;AAAI;EACI,cDLC;ACOT;AAAI;EACI,cDRC;ECSD,gCAAA;AAER","sourcesContent":["/* Media parameters */\r\n$mobile-max: 768px\r\n$tablet-max: 1210px\r\n$desktop-max: 1440px\r\n\r\n// Отступы от краев экрана\r\n$mobile-offset: 8px\r\n$desktop-offset: 64px\r\n\r\n/* Colors */\r\n$base-white: #FFFFFF\r\n$base-black: #333333\r\n$base-shadow: #0500FF40\r\n$accent: #8000FF\r\n$accent-darken: #6000BF\r\n$link: #007AFF\r\n$error: #D60056\r\n// Также отвечает и за disabled\r\n$placeholder: #33333360\r\n\r\n/* Fonts */\r\n$fm-primary: 'Roboto Mono', 'Arial', monospace","@use \"../variables\" as *\r\n@use \"../mixins\" as mix\r\n\r\n\r\n.pagination\r\n    display: flex\r\n    gap: 24px\r\n\r\n.pagination-button\r\n    @include mix.text-1\r\n    cursor: pointer\r\n    padding: 4px 8px\r\n    font-family: $fm-primary\r\n    background-color: transparent\r\n    transition: all 0.3s ease\r\n    border: none\r\n\r\n    &:hover\r\n        color: $accent\r\n\r\n    &-active\r\n        color: $accent\r\n        border-bottom: 1px solid $accent","@use \"./variables\" as *\r\n\r\n@mixin text-1\r\n    font-size: 20px\r\n    line-height: 120%\r\n\r\n@mixin text-2\r\n    font-size: 16px\r\n    line-height: 110%\r\n\r\n@mixin text-3\r\n    font-size: 12px\r\n    line-height: 110%\r\n\r\n@mixin container\r\n    max-width: $desktop-max\r\n    margin: 0 auto\r\n    width: calc(100vw - $desktop-offset * 2)\r\n\r\n    @media (max-width: $mobile-max)\r\n        width: calc(100vw - $mobile-offset * 2)\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `Pagination_pagination__rLZ83`,
	"pagination-button": `Pagination_pagination-button__U8CvB`,
	"pagination-button-active": `Pagination_pagination-button-active__cN+w7`
};
export default ___CSS_LOADER_EXPORT___;
