// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Media parameters */
/* Colors */
/* Fonts */
.Hero_hero__mPY8c {
  padding: 32px 0;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1210px) {
  .Hero_hero__mPY8c {
    gap: 8px;
    flex-direction: column;
  }
}

.Hero_content__QsLL8 {
  font-size: inherit;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
@media (max-width: 1210px) {
  .Hero_content__QsLL8 {
    max-width: none;
  }
}

.Hero_description__2EF7q {
  margin: 28px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Hero_link__gxbBG {
  width: -moz-fit-content;
  width: fit-content;
}

.Hero_text__Y07K2 {
  margin: 0;
}

.Hero_image__BiFFz {
  width: 407px;
  height: 451px;
}
@media (max-width: 1210px) {
  .Hero_image__BiFFz {
    align-self: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/variables.sass","webpack://./src/assets/styles/components/pages/main/Hero.module.sass"],"names":[],"mappings":"AAAA,qBAAA;AASA,WAAA;AAWA,UAAA;ACjBA;EACI,eAAA;EACA,aAAA;EACA,8BAAA;AACJ;AACI;EALJ;IAMQ,QAAA;IACA,sBAAA;EAEN;AACF;;AADA;EACI,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;AAIJ;AAFI;EAPJ;IAQQ,eAAA;EAKN;AACF;;AAJA;EACI,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAOJ;;AALA;EACI,uBAAA;EAAA,kBAAA;AAQJ;;AANA;EACI,SAAA;AASJ;;AAPA;EACI,YAAA;EACA,aAAA;AAUJ;AARI;EAJJ;IAKQ,kBAAA;EAWN;AACF","sourcesContent":["/* Media parameters */\r\n$mobile-max: 768px\r\n$tablet-max: 1210px\r\n$desktop-max: 1440px\r\n\r\n// Отступы от краев экрана\r\n$mobile-offset: 8px\r\n$desktop-offset: 64px\r\n\r\n/* Colors */\r\n$base-white: #FFFFFF\r\n$base-black: #333333\r\n$base-shadow: #0500FF40\r\n$accent: #8000FF\r\n$accent-darken: #6000BF\r\n$link: #007AFF\r\n$error: #D60056\r\n// Также отвечает и за disabled\r\n$placeholder: #33333360\r\n\r\n/* Fonts */\r\n$fm-primary: 'Roboto Mono', 'Arial', monospace","@use \"../../../variables\" as *\r\n@use \"../../../mixins\" as mix\r\n\r\n.hero\r\n    padding: 32px 0\r\n    display: flex\r\n    justify-content: space-between\r\n\r\n    @media (max-width: $tablet-max)\r\n        gap: 8px\r\n        flex-direction: column\r\n\r\n.content\r\n    font-size: inherit\r\n    max-width: 700px\r\n    display: flex\r\n    flex-direction: column\r\n    gap: 4px\r\n\r\n    @media (max-width: $tablet-max)\r\n        max-width: none\r\n\r\n.description\r\n    margin: 28px 0 0 0\r\n    display: flex\r\n    flex-direction: column\r\n    gap: 20px\r\n\r\n.link\r\n    width: fit-content\r\n\r\n.text\r\n    margin: 0\r\n\r\n.image\r\n    width: 407px\r\n    height: 451px\r\n\r\n    @media (max-width: $tablet-max)\r\n        align-self: center"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero": `Hero_hero__mPY8c`,
	"content": `Hero_content__QsLL8`,
	"description": `Hero_description__2EF7q`,
	"link": `Hero_link__gxbBG`,
	"text": `Hero_text__Y07K2`,
	"image": `Hero_image__BiFFz`
};
export default ___CSS_LOADER_EXPORT___;
