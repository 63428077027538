import React from "react";
import PropTypes from "prop-types";
import { Icon as Iconify } from "@iconify/react";

export const Icon = ({ icon = undefined, size = { x: 24, y: 24 }, rotate = 0, ...props }) => {
    return (
        <Iconify
            icon={`${icon}`}
            width={(typeof size === "number") ? `${size}` : `${size.x}`}
            heigth={(typeof size === "number") ? `${size}` : `${size.y}`}
            style = {{ transform: [`rotate(${rotate}deg)`] }}
            
        />
    );
};

Icon.propTypes = {
    name: PropTypes.string,
    size: PropTypes.oneOfType([
        PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number
        }),
        PropTypes.number
    ]),
    rotate: PropTypes.number
};