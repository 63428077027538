export const BASE_URL = "http://212.113.117.140";

export const fetchData = async (URL, options = {}) => {
  try {
    const response = await fetch(`${BASE_URL}${URL}`, options);

    if (!response.ok) {
      throw response;
    }

    const data = await response?.json();
    if (data.error) {
      throw new Error(data.error);
    }

    return data.output;
  } catch (e) {
    throw e;
  }
};