import { React, useState, useEffect } from "react";
import { Icon } from "../Icon";
import styles from "../../assets/styles/components/form/Textarea.module.sass";
import clsx from "clsx";

export const Textarea = ({ className, validate = null, value = "", ...props }) => {
    const [errorMessage, setErrorMessage] = useState(false);

    useEffect(() => {
        if (!validate) {
            return;
        }

        setErrorMessage(validate(value));
    }, [value, validate]);

    useEffect(() => {
        setErrorMessage(false);
    }, []);

    return (
        <div className={clsx(styles["wrapper"], className)}>
            <textarea
                className={clsx(styles["textarea"], { [styles["error"]]: errorMessage })}
                defaultValue={value}
                {...props}
            />
            {errorMessage &&
                <div className={styles["error-message-wrapper"]}>
                    <Icon className={styles["icon"]} icon="fluent:error-circle-48-regular" size= {{ "x": 16, "y": 16 }} />
                    <span className={styles["error-message"]}>
                        {errorMessage}
                    </span>
                </div>
            }
        </div>
    );
};