import { React } from "react";
import { Hero } from "../components/pages/main/Hero";
import { Feedback } from "../components/pages/main/Feedback";
import styles from "../assets/styles/components/pages/main/Main.module.sass";


export const Main = () => {
  return (
    <main className={styles["main"]}>
      <Hero />
      {/* <DesktopInfo /> */}
      <Feedback />
    </main>
  );
};