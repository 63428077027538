import clsx from "clsx";
import styles from "../../assets/styles/components/editor/Panel.module.sass";
import { Icon } from "../Icon";
import { Input } from "../form/Input";
import { Select } from "../form/Select";
import { Tag } from "../Tag";
import { useState, useEffect, useCallback, useRef } from "react";
import { Button } from "../Button";
import set from "lodash.set";

const alingLabels = clsx(styles["flex-row"], styles["flex-row-base"]);
const rowSpaceBetween = clsx(styles["flex-row"], styles["flex-space-between"]);
const columnSpaceBetween = clsx(styles["flex-column"], styles["flex-space-between"]);

const DataTagInput = ({ ...props }) =>{

    const [tags, setTags] = useState([]);
    const [tagKey, setTagKey] = useState(0);
    const [newTagName, setNewTagName] = useState("");
    
    const removeByKey = (key) => {
        let newTags = [...tags.filter(e=>e.key!=key)];
        setTags(newTags);
        props.onChange(newTags.map(e => e.name));
    };

    const createNewTag = () => {

        if(newTagName===""){
            console.error("Некорректное имя тега");
            throw new Error("Некорректное имя тега");
        }

        let newTags = [
            ...tags,
            {
                name: newTagName,
                key: tagKey
            }
        ];
        setTags(newTags);
        props.onChange(newTags.map(e => e.name));
        setTagKey(tagKey+1);
    };

    useEffect(() =>{
        if(props.data){
            let newTags = [];
            let i=0;
            props.data.forEach(element => {
                newTags.push({
                    name: element,
                    key: i
                });
                i++;
            });
            setTags(newTags);
            setTagKey(newTags.length);
            props.onChange(newTags.map(e => e.name));
        }
        return;
    }, []);

    return <div className={ clsx(styles["data"]) }>
        <p className={ clsx(styles["heading-3"], styles["heading"]) }>{props.title ? props.title : "Раздел"}</p>
        <div className={ clsx(styles["tags"]) }>
            {
            tags.map(e => <Tag name={e.name} remove={true} key={e.key} onRemove={ (event) => { removeByKey(e.key); } } /> )
            }
        </div>
        
        <div className={ clsx(styles["new"], styles["data-input"]) }>
            <Input
                className={ clsx(styles["grow-1"]) }
                onChange={(event) => { setNewTagName(event.target.value); }}
                placeholder={"Название переменной"}
                type="text"
            />
            <div className={ clsx(styles["not-grow"]) } onClick={()=>{ createNewTag(); }}>
                <Icon
                    icon="fluent:add-circle-12-regular"
                    rotate={0}
                    size={16}
                />
            </div>
        </div>
    </div>;
};

const DataTextInput = ({ ...props }) =>{

    const [texts, setText] = useState([]);
    const [textKey, setTextKey] = useState(0);
    
    const removeByKey = (key) => {
        let newText = [...texts.filter(e=>e.key!=key)];
        setText(newText);
        props.onChange(newText.map(e => e.value));
    };

    const changeValue = (key, value) =>{
        let text = [];
        for (let i = 0; i < texts.length; i++) {
            const element = texts[i];
            if(element.key == key)
                element.value = value;
            text.push(element);
        }
        setText(text);
        props.onChange(text.map(e => e.value));
    };

    const createNewText = (value, ...other) => {
        let newText = [
            ...texts,
            {
                value:value,
                key: textKey,
                ...other
            }
        ];
        setText(newText);
        props.onChange(newText.map(e => e.value));
        setTextKey(textKey+1);
    };

    useEffect(() =>{
        if(props.data){
            let newText = [];
            let i=0;
            props.data.forEach(element => {
                newText.push({
                    value: element,
                    key: i
                });
                i++;
            });
            setText(newText);
            setTextKey(newText.length);
            props.onChange(newText.map(e => e.value));
        }
        return;
    }, []);

    return <div className={ clsx(styles["data"]) }>
        <p className={ clsx(styles["heading-3"], styles["heading"]) }>{props.title ? props.title : "Раздел"}</p>
        <div>
            {
            texts.map(e => <Input
                onChange={(event) => { changeValue(e.key, event.target.value); }}
                removeFunc = { (event) => { removeByKey(e.key); } }
                placeholder={e.placeholder}
                remove
                type="text"
                key={e.key}
                value={e.value}
              /> )
            }
        </div>
        <div className={ clsx(styles["not-grow"]) } onClick={()=>{ createNewText(); }}>
            <Icon
                icon="fluent:add-circle-12-regular"
                rotate={0}
                size={16}
            />
        </div>
    </div>;
};


const PanelMathModel = ({ className, onNext, setActive, setInput, setOutput, setLimits, setFormulas, setTitle, localData, onSave, getDLLFile, setConst, ...props }) =>{
    if(localData.projectNameData)
        setTitle(localData.projectNameData);

    return (
        <div className={ clsx(styles["math-model"], { [styles[className]]: className !== undefined } ) }>
                <div className={ clsx(styles["control"]) }>
                    <div className={ clsx(styles["data"]) }>
                        <Input
                            className={ clsx(styles["project-title"]) }
                            onChange={ (event) => setTitle(event.target.value) }
                            placeholder="Название проекта"
                            type="text"
                            defaultValue={localData.projectNameData}
                        />
                        <DataTextInput
                            data={localData.const}
                            title={"Константы"}
                            onChange={setConst}
                        />
                        <DataTagInput
                            data={localData.inputData}
                            title={"Параметры"}
                            onChange={setInput}
                        />
                        <DataTagInput
                            data={localData.outputData}
                            title={"Критерии"}
                            onChange={setOutput}
                        />
                        <DataTextInput
                            data={localData.limitsData}
                            title={"Орграничения"}
                            onChange={setLimits}
                            />
                        <DataTextInput
                            data={localData.formulasData}
                            title={"Формулы"}
                            onChange={setFormulas}
                        />
                    </div>
                    <div className={ clsx(styles["buttons-block"]) }>
                        <div className={ clsx(styles["buttons-line"]) }>
                            <label>
                                { true && <label htmlFor="DLL"><a>Загрузить DLL</a></label> }
                                <Input
                                    className={clsx(styles["hide"])}
                                    id={"DLL"}
                                    // ref={DLLRef}
                                    onChange={(event) => { getDLLFile(event.target); }}
                                    placeholder="Замещающий текст"
                                    type="file"
                                />
                            </label>
                        </div>
                        <div className={ clsx(styles["buttons-line"]) }>
                            <a>Загрузить проект</a>
                            <Button onClick={onSave}>Сохранить</Button>
                        </div>
                        <div className={ clsx(styles["buttons-line"]) }>
                            <a>Руководство</a>
                            <Button onClick={ (event)=>{ onNext(event); } }>Далее</Button>
                        </div>
                    </div>
                </div>
            </div>
    );
};

const SelectControl = ({ labelName, options, index, onChange }) => {

    return (
        <div className={ alingLabels }>
            <label className={ alingLabels } >{labelName}</label>
            <Select options={options} chooseOptionText="-" onChange={(value) => { onChange(index, "name", value); }} />
            <label className={ alingLabels } >max: <Input type="number" onChange={(event) => { onChange(index, "max", event.target.value); }}/> min: <Input type="number" onChange={(event) => { onChange(index, "min", event.target.value); }}/></label>
        </div>
    );
};

const PanelControlMeasures = ({ className, setActive, input, getVariables, getMaxStepNumbers, getMinChange, defaultValue, onSave, getDLLFile, onSend, ...props }) => {

    const [variables, setVariables] = useState([]);

    //Annotation может потом потребуется для проверки значений
    const [max_step_numbers, setMaxStepNumber] = useState(defaultValue?.max_step_numbers);
    const [min_change, setMinChange] = useState(defaultValue?.min_change);
    
    const changeParam = (index, key, value) => {
        let _variables = [ ...variables ];
        if(index == "all"){
            for (let i = 0; i  < _variables.length; i++) {
                if(key=="max"||key=="min"||key=="grid")
                    _variables[i][key]=Number(value);
                else
                    _variables[i][key]=value;
            }
            setVariables(_variables);
            return;
        }
        while(index>=_variables.length){
            _variables.push({});
        }
        _variables[index][key]=value;
        setVariables(_variables);
        getVariables(_variables);
        return;
    };

    const changeMaxStepNumber = (value) => {
        setMaxStepNumber(value);
        getMaxStepNumbers(value);
    };

    const changeMinChange = (value) => {
        setMinChange(value);
        getMinChange(value);
    };

    

    return (
        <div className={ clsx(styles["val"], { [styles[className]]: className !== undefined } ) }>
            <div className={ clsx(styles["control"]) }>
                <div className={ clsx(styles["data"]) }>
                    <SelectControl labelName={"X:"} options={input} index={0} onChange={ changeParam } />
                    <SelectControl labelName={"Y:"} options={input} index={1} onChange={ changeParam } />
                    { /* <SelectControl labelName={"Z:"} options={input} onChange={setThirdParam}/> */}
                    <div className={ columnSpaceBetween }>
                        <label className={ rowSpaceBetween }>Кол-во точек <Input type="number" onChange={(event) => { changeParam("all", "grid", event.target.value); }}/></label>
                        <label className={ rowSpaceBetween }>Максимальное число шагов <Input type="number" defaultValue={defaultValue?.max_step_numbers} onChange={(event) => { changeMaxStepNumber(event.target.value); }}/></label>
                        <label className={ rowSpaceBetween }>Требуемая точность <Input type="number" defaultValue={defaultValue?.min_change} onChange={(event) => { changeMinChange(event.target.value); }}/></label>
                    </div>
                </div>
                <div className={ clsx(styles["buttons-block"]) }>
                    <div className={ clsx(styles["buttons-line"]) }>
                        <a>Загрузить проект</a>
                        <Button onClick={onSave}>Сохранить</Button>
                    </div>
                    <div className={ clsx(styles["buttons-line"]) }>
                        <a>Руководство</a>
                        <Button onClick={ (event)=>{ onSend(); } }>Построить</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const OptimizeControl = ({ labelObject, updateObject, defaultValue, ...props }) => {

    const [start, setStart] = useState(defaultValue?.start_point);
    const [value_type, setValueType] = useState(defaultValue?.value?.type);
    const [value_n_point, setValueNPoint] = useState(defaultValue?.value?.n_point);
    const [value_n_iteration, setValueNIteration] = useState(defaultValue?.value?.n_iteration);

    const changeStart = (value) => {
        updateObject([labelObject, "start_point"], value);
        setStart(value);
    };

    const changeValueType = (value) => {
        updateObject([labelObject, "value", "type"], value);
        setValueType(value);
    };

    const changeValueNPoint = (value) => {
        updateObject([labelObject, "value", "n_point"], value);
        setValueNPoint(value);
    };

    const changeValueNIteration = (value) => {
        updateObject([labelObject, "value", "n_iteration"], value);
        setValueNIteration(value);
    };

    const start_options = ["0,0,0,...,0", "1,1,1,...,1", "1,-1,1,...", "-1,1,-1,...", "center_of_region", "previous_optimum", "random"];
    const value_type_options = ["first_valid", "optimum"];

    return(
        <div className={ clsx(styles["optimizationParams"]) }>
            <label className={ rowSpaceBetween }>{labelObject} <Select option={["random"]} options={start_options} onChange={changeStart}/></label>
            { start == "random" &&
                <div className={ columnSpaceBetween }>
                    <label className={ rowSpaceBetween }>type <Select option={["first_valid"]} options={value_type_options} onChange={changeValueType}/></label>
                    <label className={ rowSpaceBetween }>n_point <Input defaultValue={1} onChange={(event)=> changeValueNPoint(event.target.value)}/></label>
                    <label className={ rowSpaceBetween }>n_iteration <Input defaultValue={1000} onChange={(event)=> changeValueNIteration(event.target.value)}/></label>
                </div>
            }
        </div>
    );
};

const PanelOptimization = ({ className, getObject, defaultValue, onSave, onBack, ...props }) => {

    const [optimization, setOptimization] = useState(defaultValue);

    const updateObject = (keys, value) => {
        let newOptimization = { ...optimization };
        keys=keys.join(".");
        set(newOptimization, keys, value);
        setOptimization(newOptimization);
        getObject(newOptimization);
    };

    return (
        <div className={ clsx(styles["optimization"], { [styles[className]]: className !== undefined } ) }>
            <div className={ clsx(styles["control"]) }>
                <div className={ clsx(styles["data"]) }>
                    <div>
                        <h2>Оптимизация</h2>
                        <p>Данные настройки применяются для оптимизации поиска, см. руководство, прежде чем изменять значения</p>
                    </div>
                    <OptimizeControl labelObject={"region_center"} updateObject={updateObject} defaultValue={optimization.region_center}/>
                    <OptimizeControl labelObject={"region_size"} updateObject={updateObject} defaultValue={optimization.region_size}/>
                    <OptimizeControl labelObject={"first_point_on_grid"} updateObject={updateObject} defaultValue={optimization.first_point_on_grid}/>
                    <OptimizeControl labelObject={"internal_grid_notes"} updateObject={updateObject} defaultValue={optimization.internal_grid_notes}/>
                </div>
                <div className={ clsx(styles["buttons-block"]) }>
                    <div className={ clsx(styles["buttons-line"]) }>
                        <a>Загрузить проект</a>
                        <Button onClick={onSave}>Сохранить</Button>
                    </div>
                    <div className={ clsx(styles["buttons-line"]) }>
                        <a>Руководство</a>
                        <Button onClick={ (event)=>{ onBack(); } }>Назад</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const Panel = ({ className, callBack, ...props }) => {
    //controls Component
    const [active, setActive] = useState("measure");

    const swithActive = (name) =>{
        if(active == name)
            setActive(undefined);
        else
            setActive(name);
    };

    //input and data of project
    const [DLLfile, setDLLFile] = useState();
    const DLLRef = node => {
        if (node !== null) {
            setDLLFile(node.files[0]);
        }
      };

    const [input, setInput] = useState([]);
    const [consts, setConsts] = useState([]);
    const saveConsts = (consts) => {
        let _consts = [];
        consts.forEach(e=>{
            let _const = {};
            if(e.match(/=/g).length == 1){
                e=e.replace(" ", "").split("=");
                
                const regexNumber = new RegExp("-?[\d\\.]+", "g");
                if(regexNumber.test(e[0])){
                    _const.value = Number(e[0]);
                    _const.name = e[1];
                }else{
                    _const.value = Number(e[1]);
                    _const.name = e[0];
                }
            }
            _consts.push(_const);
        });
        return _consts;
    };
    const [output, setOutput] = useState([]);
    const [limits, setLimits] = useState([]);
    const loadLimits = (input, output) => {
        let limits = [];
        input.forEach(e=>{
            let limit = "";
            if(e.min!==undefined)
                limit = e.min + "<=" + e.name;
            if(e.max !==undefined)
                limit = limit == "" ? e.name + "<=" + e.max : limit + "<=" + e.max;
            if( limit != "")
                limits.push(limit);
        });
        output.forEach(e=>{
            let limit = "";
            if(e.min!==undefined)
                limit = e.min + "<=" + e.name;
            if(e.max !==undefined)
                limit = limit == "" ? e.name + "<=" + e.max : limit + "<=" + e.max;
            if( limit != "")
                limits.push(limit);
        });
        return limits;
    };
    const saveLimits = (exportData, input, output) => {
        let input_limits = [];
        let output_limits = [];
        for (let i = 0; i < exportData.length; i++) {
            let result_object = {};
            let element = exportData[i];
            if(!(element.includes("<=") || element.includes(">=")))
                throw new Error("Нет знака сравнения <= или >=");
            let condition = element.includes("<=") ? "<=" : element.includes(">=") ? ">=" : "";
            element = element.includes("<=") ? element.split("<=") : element.includes(">=") ? element.split(">=") : [];
            const regexNumber = /-?[\d\\.]+/;
            const regexValue = /-?[A-Za-z]+[A-Za-z0-9]*/;
            let inner = true;
            element.forEach((e)=>{
                if(!(regexNumber.test(e) || regexValue.test(e)))
                    throw new Error("Встречена строка \"" + e + "\", которую не удалось распознать в" + element);
                if(regexValue.test(e))
                    if(input.includes(e))
                        inner = "input";
                    if(output.includes(e))
                        inner = "output";
            });
            if(inner === true) continue;
            
            if(element.length > 2){
                result_object = condition == ">=" ?
                { name:element[1], max: Number(element[0]), min: Number(element[2]) } :
                { name:element[1], max: Number(element[2]), min: Number(element[0]) };
            } else {
                result_object = condition == ">=" ?
                    regexNumber.test(element[1]) ?
                        { name:element[0], min: Number(element[1]) } :
                        { name:element[1], max: Number(element[0]) }
                        :
                    regexNumber.test(element[1]) ?
                        { name:element[0], max: Number(element[1]) } :
                        { name:element[1], min: Number(element[0]) };
            }
            if(inner == "input")
                input_limits.push(result_object);
            if(inner == "output")
                output_limits.push(result_object);
        }

        input.forEach(e=>{
            if(!input_limits.map(elem=>elem.name).includes(e))
                input_limits.push({ name:e });
        });

        output.forEach(e=>{
            if(!output_limits.map(elem=>elem.name).includes(e))
                output_limits.push({ name:e });
        });

        return {
            input: input_limits,
            output: output_limits
        };
    };
    const [formulas, setFormulas] = useState([]);
    const [title, setTitle] = useState([]);
    //TODO: Соединить с PanelControlMeasures
    const [variables, setVariables] = useState([]);
    const [max_step_numbers, setMaxStepNumbers] = useState(100);
    const [min_change, setMinChange] = useState(0.0001);
    const [optimization, setOptimization] = useState({
        "region_center": {
            "start_point": "random",
            "value": {
                "type": "first_valid",
                "n_point": 1,
                "n_iteration": 1000
            }
        },
        "region_size": { "start_point": "center_of_region" },
        "first_point_on_grid": {
            "start_point": "random",
            "value": {
                "type": "first_valid",
                "n_point": 1,
                "n_iteration": 1000
            }
        },
        "internal_grid_notes": { "start_point": "previous_optimum" }
    });

    const onSave = ()=>{
        const exportData = new CustomEvent("export", {});
        document.dispatchEvent(exportData);
        window.localStorage.setItem("title", JSON.stringify(title));
        let save_limits = saveLimits(limits, input, output);
        window.localStorage.setItem("input", JSON.stringify(save_limits.input));
        window.localStorage.setItem("output", JSON.stringify(save_limits.output));
        window.localStorage.setItem("limits", JSON.stringify(limits));
        window.localStorage.setItem("formulas", JSON.stringify(formulas));

        let _consts = saveConsts(consts);

        let _variables = [ ...variables ];
        for (let j = 0; j < _variables.length; j++) {
            for (let i = 0; i < Object.keys(_variables[j]).length; i++) {
                const key = Object.keys(_variables[j])[i];
                if(key=="max"||key=="min"||key=="grid")
                    _variables[j][key] = Number(_variables[j][key]);
            }
        }

        let calculate_params = {
            variables: _variables,
            max_step_numbers: Number(max_step_numbers),
            min_change: Number(min_change),
            optimization: optimization
        };

        let project = {
            title: title,
            const:_consts,
            input: save_limits.input,
            output: save_limits.output,
            formulas: formulas,
            calculate_params: calculate_params
        };

        window.localStorage.setItem("optimization", JSON.stringify(optimization));
        
        window.localStorage.setItem("project", JSON.stringify(project));

        console.log("saved");
    };

    const onSend = () => {
        let save_limits = saveLimits(limits, input, output);
        let _consts = saveConsts(consts);

        let _variables = [ ...variables ];
        for (let j = 0; j < _variables.length; j++) {
            for (let i = 0; i < Object.keys(_variables[j]).length; i++) {
                const key = Object.keys(_variables[j])[i];
                if(key=="max"||key=="min"||key=="grid")
                    _variables[j][key] = Number(_variables[j][key]);
            }
        }

        let calculate_params = {
            variables: _variables,
            max_step_numbers: Number(max_step_numbers),
            min_change: Number(min_change),
            optimization: optimization
        };
        let project = {
            title: title,
            const:_consts,
            input: save_limits.input,
            output: save_limits.output,
            //formulas: formulas,
            calculate_params: calculate_params
        };
        if (DLLfile?.name)
            project.file = DLLfile.name;

        let form=new FormData();
        form.append("dll_file", DLLfile);
        form.append("task", JSON.stringify(project));
        fetch(new URL("http://212.113.117.140/calculate/calculate.php"), {
            method: "POST",
            body: form,
            //mode: "no-cors"
        }).then((r) => {
            r.json().then((result)=> {
                if(callBack!==undefined)
                    callBack(result);
            });
        });
    };

    const loadLocalData = () => {
        //TODO: load form localStorage "project"
        const localData = {};
        const project = JSON.parse(window.localStorage.getItem("project")) ? JSON.parse(window.localStorage.getItem("project")) : undefined;
        if(project===undefined)
            return localData;
        localData.projectNameData = project.title;
        localData.inputData = project.input.map(e => e.name);
        localData.outputData = project.output.map(e => e.name);
        localData.limitsData = loadLimits(
            project.input ? project.input : [],
            project.output ? project.output : []
        );
        localData.formulasData = project.formulas ? project.formulas : [];
        localData.const = project.const.map(e=> e.name+"="+e.value);

        return localData;
    };

    const localData = loadLocalData();
    

    return (
        <div className={ clsx(styles["panel"], className) }>
            <div className={ clsx(styles["panels"]) }>
                <PanelMathModel
                    className={active === "math" ? "active" : undefined }
                    setInput={setInput}
                    setOutput={setOutput}
                    setLimits={setLimits}
                    setFormulas={setFormulas}
                    setTitle={setTitle}
                    localData={localData}
                    onNext={ () => swithActive("measure") }
                    onSave={ onSave }
                    setActive={swithActive}
                    getDLLFile={DLLRef}
                    setConst={setConsts}
                />
                <PanelControlMeasures
                    className={active === "measure" ? "active" : undefined }
                    setActive={swithActive}
                    input={input}
                    onSave={ onSave }
                    getVariables={ setVariables }
                    getMaxStepNumbers={ setMaxStepNumbers }
                    getMinChange={ setMinChange }
                    defaultValue={{ max_step_numbers:max_step_numbers, min_change:min_change }}
                    onSend={ onSend }
                />
                <PanelOptimization
                    className={active === "optimize" ? "active" : undefined }
                    getObject={setOptimization}
                    defaultValue={optimization}
                    onSave={ onSave }
                    onBack={ () => swithActive("measure") }
                />
            </div>
            <div className={ clsx(styles["labels"]) }>
                <div className={ clsx(styles["label"]) } onClick={() => setActive("math")}>
                    <Icon
                        icon="fluent:math-formula-16-filled"
                        rotate={0}
                        size={24}
                    />
                </div>
                <div className={ clsx(styles["label"]) } onClick={() => setActive("measure")}>
                    <Icon
                        icon="fluent:braces-variable-20-filled"
                        rotate={0}
                        size={24}
                    />
                </div>
                <div className={ clsx(styles["label"]) } onClick={() => setActive("optimize")}>
                    <Icon
                        icon="material-symbols:tv-options-input-settings-outline-rounded"
                        rotate={0}
                        size={24}
                    />
                </div>
            </div>
        </div>
    );
};