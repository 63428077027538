import { React } from "react";
import { Heading } from "../../Heading";
import styles from "../../../assets/styles/components/pages/main/Hero.module.sass";
import linkStyles from "../../../assets/styles/components/Link.module.sass";
import heroImg from "../../../assets/images/hero-image-min.jpg";
import fileSrc from "../../../assets/files/image-sapr.pdf";
import clsx from "clsx";

export const Hero = () => {
  return (
    <section className={styles["hero"]}>
      <div className={styles["content"]}>
        <Heading level={2} className={clsx(styles["hero-heading"])}>
          Система визуальных вычислений
        </Heading>
        <div className={styles["description"]}>
          <p className={styles["text"]}>
            <strong>Онлайн система Impro</strong> предназначена для исследования математических моделей, нахождения допустимых и оптимальных решений в технических и экономических задачах.
            Работа пользователя в Impro основана на взаимодействии с представлением пространства допустимых решений в графической форме поэтому процесс назван «визуальные вычисления».
          </p>
          <p className={styles["text"]}>
            <strong>Impro может использоваться</strong> во многих предметных областях (инженерных, экономических<>&nbsp;</>и<>&nbsp;</>др.), где математические модели имеют набор управляемых параметров, от которых зависят характеристики.
            Требования (ограничения) к характеристикам задают область допустимых значений параметров. Система <strong>Impro</strong> позволяет представить в графическом виде множество допустимых параметров и выполнить оптимизацию по выбранным критериям – получить допустимые и оптимальные решения.
          </p>
          <p className={styles["text"]}>
            <strong>Impro реализует «метод проекций»</strong> (<a download="sapr" className={clsx(styles["link"], linkStyles["link"])} href={fileSrc} >подробнее</a>) и может использоваться как самостоятельная программа, а также быть вычислительной частью более крупной системы проектирования. Прототип Impro – ранее разработанная программа Image для настольных персональных компьютеров.
          </p>
          <p className={styles["text"]}>
            В разделе Информация приведено описание метода, статьи и примеры использования Impro. Выбрав пример, можно открыть описание задачи в редакторе и выполнить процесс решения.
          </p>
        </div>
      </div>
      <img className={styles["image"]} src={heroImg} alt="Визуализация вычислений" width="407" height="451"/>
    </section>
  );
};