import { React } from "react";
import PropTypes, { bool, string, node, element } from "prop-types";
import styles from "../../assets/styles/components/form/Label.module.sass";
import clsx from "clsx";

export const Label = ({ required = false, children, ...props }) => {
  return (
    <label className={clsx(styles["label"], { [styles["label-required"]]: required })} {...props}>
      { children }
    </label>
  );
};

Label.propsTypes = {
  required: bool,
  children: PropTypes.oneOfType([
    node,
    element,
    string
  ])
};