import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { clsx } from "clsx";
import styles from "../../../assets/styles/components/pages/articles/Example.module.sass";
import linkStyles from "../../../assets/styles/components/Link.module.sass";

export const Example = ({ example }) => {
  const { src, name } = example;
  const [exampleFile, setExampleFile] = useState(null);
  const navigate = useNavigate();

  const openExample = () => {
    window.localStorage.setItem("points", JSON.stringify(exampleFile));
    navigate("/editor");
  };

  useEffect(() => {
      if (!src) {
          return;
      }

      const fetchFile = async () => {
          try {
              const response = await import(`../../../assets/files/${src}`);
              setExampleFile(response.default);
          } catch (err) {
              setExampleFile(null);
          }
      };

      fetchFile();
  }, [src]);


  return(
    <div className={clsx(styles["example"], linkStyles["link"])} onClick={openExample}>
      { name }
    </div>
  );
};