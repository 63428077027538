import { React, useState } from "react";
import { Label } from "../../form/Label";
import { Input } from "../../form/Input";
import { Textarea } from "../../form/Textarea";
import { Button } from "../../Button";
import styles from "../../../assets/styles/components/pages/main/FeedbackForm.module.sass";

const validateName = (value) => {
  if (!value.trim().length) {
    return "Имя не может быть пустым";
  }

  return false;
};

const validateEmail = (value) => {
  const emailRegexp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  if (!value.match(emailRegexp)) {
    return "Пожалуйста, введите корректный email";
  }

  return false;
};

const validateTextarea = (value) => {
  if (!value.trim().length) {
    return "Предложения не могут быть пустыми";
  }

  if (value.length > 3000) {
    return "Допустимо не более 3000 символов";
  }
};

export const FeedbackForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [feedback, setFeedback] = useState({
    name: "",
    email: "",
    text: ""
  });

  const submitFeedbackForm = async (event) => {
    const url = "";
    event.preventDefault();

    if (!validateName(feedback.name) && !validateEmail(feedback.email) && !validateTextarea(feedback.text)) {
      setIsLoading(true);

      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(feedback)
      });

      setIsLoading(false);
      console.log(response);
    }
  };

  return (
    <form className={styles["form"]} onSubmit={submitFeedbackForm}>
      <div className={styles["item"]}>
        <Label htmlFor="name" required>
          Ваше имя
        </Label>
        <Input
          value={feedback.name}
          onChange={(event) => { setFeedback({ ...feedback,  name: event.target.value }); }}
          validate={validateName}
          disabled={isLoading}
          placeholder="Введите ваше имя"
          type="text"
          name="name"
          id="name"
          required
        />
      </div>
      <div className={styles["item"]}>
        <Label htmlFor="email" required>
          Ваш email
        </Label>
        <Input
          value={feedback.email}
          onChange={(event) => { setFeedback({ ...feedback,  email: event.target.value }); }}
          validate={validateEmail}
          disabled={isLoading}
          placeholder="Введите ваш email"
          type="email"
          name="email"
          id="email"
          required
        />
      </div>
      <div className={styles["item"]}>
        <Label htmlFor="text" required>
          Ваши предложения
        </Label>
        <Textarea
          value={feedback.text}
          onChange={(event) => { setFeedback({ ...feedback,  text: event.target.value }); }}
          validate={validateTextarea}
          disabled={isLoading}
          className={styles["textarea"]}
          id="text"
          name="text"
          placeholder="Введите ваши предложения"
          minLength="1"
          required
        />
      </div>
      <Button disabled={isLoading} className={styles["button"]} type="submit">
        Отправить
      </Button>
    </form>
  );
};