import { createBrowserRouter } from "react-router-dom";
import { Main } from "./Main";
import { Editor } from "./Editor";
import { Error } from "./Error";
import { Layout } from "./Layout";
import { Articles } from "./Articles";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Main />
      },
      {
        path: "editor",
        element: <Editor />
      },
      {
        path: "articles",
        element: <Articles />
      },
      {
        path: "*",
        element: <Error />
      }
    ]
  },
]);