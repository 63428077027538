import { React, useEffect, useState } from "react";
import PropTypes, { func, string, array } from "prop-types";
import { Icon } from "../Icon";
import styles from "../../assets/styles/components/form/Select.module.sass";
import clsx from "clsx";


export const Select = ({ className, option, options=[], onChange, chooseOptionText, ...props }) => {
    const [rotate, setRotate] = useState(180);

    //TODO: заменить select на внутренний элемент допускающий все функции

    return (
        <div className={clsx(styles["wrapper"], className)}>
            <select className={clsx(styles["select"])} {...props} onFocus={()=>{ setRotate(0); }} onBlur={()=>{ setRotate(180); }} onChange={(event)=> { onChange(event.target.value); }}>
                {chooseOptionText && <option value={chooseOptionText} selected={ chooseOptionText ? true : false } disabled>{chooseOptionText}</option>}
                {options.map(e => <option value={e} selected={ option==e ? true : false }>{e}</option>)}
            </select>
            <Icon
                icon="material-symbols:arrow-drop-up-rounded"
                rotate={rotate}
                size={24}
            />
        </div>
    );
};

Select.propTypes = {
    options: array,
    onChange: func
};