import { React, useEffect, useState } from "react";
import PropTypes, { func, string } from "prop-types";
import { Icon } from "../Icon";
import styles from "../../assets/styles/components/form/Input.module.sass";
import clsx from "clsx";


export const Input = ({ className, validate = null, value = "", removeFunc = undefined, ref, ...props }) => {
    const [errorMessage, setErrorMessage] = useState(false);

    useEffect(() => {
        if (!validate) {
            return;
        }

        setErrorMessage(validate(value));
    }, [value, validate]);

    useEffect(() => {
        setErrorMessage(false);
    }, []);

    const remove = (event) =>{
        console.warn("Был установлен флаг remove, но небыло указано действие onRemove", event);
    };

    return (
        <div className={clsx(styles["wrapper"], className)}>
            <input
                className={clsx(styles["input"], { [styles["error"]]: errorMessage })}
                defaultValue={value}
                ref={ref ? ref : undefined}
                {...props}
            />
            {
            props.remove &&
                <div className={ clsx(styles["remove"]) } onClick={removeFunc ? removeFunc : (event) => remove(event)}>
                    <Icon
                        icon="fluent:delete-12-regular"
                        rotate={0}
                        size={16}
                    />
                </div>
            }
            {errorMessage &&
                <div className={styles["error-message-wrapper"]}>
                    <Icon className={styles["icon"]} icon="fluent:error-circle-48-regular" size= {{ "x": 16, "y": 16 }} />
                    <span className={styles["error-message"]}>
                        {errorMessage}
                    </span>
                </div>
            }
        </div>
    );
};

Input.propTypes = {
    placeholder: string,
    validate: func,
    type: PropTypes.oneOf(["email", "number", "password", "search", "tel", "text", "file"]),
};