import clsx from "clsx";
import { React } from "react";
import { number, func } from "prop-types";

import styles from "../assets/styles/components/Pagination.module.sass";

export const Pagination = ({ totalPages, value, onChange }) => {
  const getVisibleValues = () => {
    if (totalPages === 1) {
      return [totalPages];
    }

    if (totalPages === 2) {
      return [totalPages - 1, totalPages];
    }

    if ((totalPages - value >= 2) && (value - 1 > 0)) {
      return [value - 1, value, value + 1];
    }

    // Левые границы пагинации
    if ((totalPages - value >= 2) && value === 1) {
      return [value, value + 1, value + 2];
    }

    // Правые границы пагинации
    if ((totalPages - value) < 2) {
      return [totalPages - 2, totalPages - 1, totalPages];
    }
  };

  const visibleValues = getVisibleValues();

  return (
    <div className={clsx(styles["pagination"])}>
      {
        visibleValues.map((pageNumber) => (
          <button
          key={pageNumber}
          onClick={() => onChange(pageNumber)}
          className={clsx(
            styles["pagination-button"],
            { [styles["pagination-button-active"]]: pageNumber === value }
          )}
          >
            { pageNumber }
          </button>
        ))
      }
    </div>
  );
};

Pagination.propTypes = {
  totalPages: number,
  value: number,
  onChange: func
};