// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Media parameters */
/* Colors */
/* Fonts */
.Link_link__H-h-t {
  font-family: "Roboto Mono", "Arial", monospace;
  color: #007AFF;
  text-decoration: underline;
  text-decoration-color: transparent;
  text-underline-offset: 3px;
  transition: all 0.3s ease;
  cursor: pointer;
}
.Link_link__H-h-t:hover {
  text-decoration-color: currentColor;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/variables.sass","webpack://./src/assets/styles/components/Link.module.sass"],"names":[],"mappings":"AAAA,qBAAA;AASA,WAAA;AAWA,UAAA;AClBA;EACI,8CDkBS;ECjBT,cDWG;ECVH,0BAAA;EACA,kCAAA;EACA,0BAAA;EACA,yBAAA;EACA,eAAA;AAEJ;AAAI;EACI,mCAAA;AAER","sourcesContent":["/* Media parameters */\r\n$mobile-max: 768px\r\n$tablet-max: 1210px\r\n$desktop-max: 1440px\r\n\r\n// Отступы от краев экрана\r\n$mobile-offset: 8px\r\n$desktop-offset: 64px\r\n\r\n/* Colors */\r\n$base-white: #FFFFFF\r\n$base-black: #333333\r\n$base-shadow: #0500FF40\r\n$accent: #8000FF\r\n$accent-darken: #6000BF\r\n$link: #007AFF\r\n$error: #D60056\r\n// Также отвечает и за disabled\r\n$placeholder: #33333360\r\n\r\n/* Fonts */\r\n$fm-primary: 'Roboto Mono', 'Arial', monospace","@use \"../variables\" as *\r\n\r\n.link\r\n    font-family: $fm-primary\r\n    color: $link\r\n    text-decoration: underline\r\n    text-decoration-color: transparent\r\n    text-underline-offset: 3px\r\n    transition: all 0.3s ease\r\n    cursor: pointer\r\n\r\n    &:hover\r\n        text-decoration-color: currentColor\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `Link_link__H-h-t`
};
export default ___CSS_LOADER_EXPORT___;
